@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons';
  src: local('Montserrat-Bold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

/* @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); * For IE6-8 *
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
} */

h1, h2, h3, h4 {
  font-family: 'Montserrat-Bold';
}
h1 {
  font-size: 35px !important;/*default: 40*/
}
h2 {
  font-size: 24px !important;/*default: 32*/
}

/* 
Extra small devices (portrait phones, less than 544px) 
No media query since this is the default in Bootstrap because it is "mobile first"
*/
div {font-size:1rem;} /*1rem = 16px*/
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {  
  div {font-size:1.1rem;} /*1rem = 16px*/
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
  div {font-size:1.1rem;} /*1rem = 16px*/
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
  div {font-size:1.2rem;} /*1rem = 16px*/
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
  div {font-size:1.4rem;} /*1rem = 16px*/    
}
@media (min-width: 1440px) {  
  div {font-size:1.6rem;} /*1rem = 16px*/    
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Montserrat';
  background-color: #56d9ff;
  min-height: 100vh;
}

.Header {
  display: flex;
  flex-direction: column;
  /* height: 40vmin; */
  margin: 5%;
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);/*#e6f9ff*/
  border-radius: 20px;
  border: 2px solid #8abbcc;
  padding: 20px;
  text-align: left;
  /* flex-direction: column;
  text-align: center;
  font-family: 'Montserrat';
  background-color: #56d9ff; */
}

.download-button-container {
  /* flex-direction: row; */
  /* border: 1px solid black; */
  /* justify-content: 'center';
  align-items: 'center'; */
}

.header-text {
  /* font-size: 15pt; */
}

div.mybutton {
  border: 1px solid green;
  /* background-color: 'white'; */
  text-align: 'center';
  /* align-items: 'center';
  justify-content: 'center'; */
}

img.download-button {
  /* height: 12vmin; */
  width: 60%;
  /* pointer-events: none; */
  /* border: 1px solid red; */
  /* margin: -20px; */
}

video.video {
  height: 60vmin
}

.mydiv {
  /* border: 1px solid black; */
  height: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  /* margin: -40px; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  /* background-color: #56d9ff; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12pt; /* calc(10px + 2vmin); */
  color: black;
}

.App-link {
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

img.navbar-logo {
  background-color: white;
  border-radius: 100px;
  margin-right: 15px;
}

.navbar {
  font-family: 'Montserrat';
}

.AlarmContainer {
  /* display: flex; */
  /* flex-direction: row; */
  direction: horizontal;
  /* justify-content: space-between; */
  align-items: center;
  border: 2px solid #8abbcc;
  margin-bottom: 2vh;
  padding: 1vh;
  font-family: 'Montserrat-bold';
  /* width: 50% */
  background-color: #e6f9ff;
}

.AlarmContainer:hover {
  background-color: #8abbcc;
}

.AlarmColumn {
  /* vertical-align: middle; */
  /* border: 1px solid green; */
  padding-left: 2vh;
  padding-right: 2vh;
}

.no-margin {
  margin: 100
}